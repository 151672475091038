import React from 'react';
import styled from '@emotion/styled';
import media from '../../shared/media';
import { Text } from '../../components';
import { COLORS } from '@chronos/constants';

export default function AffiliateSignupTopCompanionFreelancingFreedom() {
  return (
    <TopHanger>
      <TopText>Brad Hussey's Listeners: Sign up now for a free month of Chronos Pro!</TopText>
    </TopHanger>
  );
}

const TopHanger = styled.div({
  display: 'none',
  width: '100%',
  backgroundColor: COLORS.PURPLE,
  borderBottomLeftRadius: 20,
  borderBottomRightRadius: 20,

  textAlign: 'center',
  padding: '14px 14px 20px',

  [media('small')]: {
    display: 'block',
  }
});

const TopText = styled(Text)({
  color: COLORS.WHITE,

  lineHeight: '32px',
  fontSize: '4.2vw',
});
