import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Text } from './';
import { appleAppStoreLink, googlePlayStoreLink } from '../shared/constants';
import { COLORS, Shadows } from '@chronos/constants';
import Icon from './Icon';
import { AppleLogoIcon, AndroidLogoIcon } from '../shared/icons';
import { isMobileBrowser } from '../shared/utils';

export interface MobileAppInfoBannerProps {
}

export default function MobileAppInfoBanner(props: MobileAppInfoBannerProps) {
  const IS_DISMISSED_KEY = 'mobileAppInfoBannerDismissed';

  const [isDismissed, setIsDismissed] = useState(localStorage.getItem(IS_DISMISSED_KEY) === 'true');

  const handleClickClose = () => {
    localStorage.setItem(IS_DISMISSED_KEY, 'true');
    setIsDismissed(true);
  };

  if (!isMobileBrowser() || isDismissed) {
    return null;
  }

  return (
    <Container>
      <LeftContainer>
        <TopText as="h2">On mobile? Get the best experience w/ our app!</TopText>
        <div>
          <MobilePlatformAnchor href={googlePlayStoreLink}>
            <AndroidLogoIcon width={20} height={26} style={{ marginRight: '10px'}} />
            <MobilePlatformText as="h2">Android</MobilePlatformText>
          </MobilePlatformAnchor>
          <IosAnchor href={appleAppStoreLink}>
            <AppleLogoIcon width={36} height={26} fill={'#030F09'} style={{ display: 'inline-block' }}/>
            <MobilePlatformText as="h2">iOS</MobilePlatformText>
          </IosAnchor>
        </div>
      </LeftContainer>
      <RightContainer>
        <Icon
          size={24}
          name="close"
          onClick={handleClickClose}
          clickable
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  position: 'fixed',
  top: 0,
  zIndex: 1000,
  width: '100%',
  backgroundColor: COLORS.WHITE,
  boxShadow: Shadows.Popover,
  borderRadius: '0px 0px 8px 8px',
  padding: '16px 20px',
});

const LeftContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
});

const RightContainer = styled.div({
  display: 'flex',
});

const TopText = styled(Text)({
  fontSize: '3.4vw',
});

const MobilePlatformText = styled(Text)({
  display: 'inline-block',
  textDecoration: 'underline',
  color: COLORS.CYAN,
});

const MobilePlatformAnchor = styled.a({
  '& > *': {
    verticalAlign: 'middle',
  }
});

const IosAnchor = styled(MobilePlatformAnchor)({
  marginLeft: '20px',
});
