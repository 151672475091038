"use strict";

var _defineProperty = require("/mnt/c/Chronos/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$ENTRY_TYPE_C, _exports$REPORTS_PROF;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REPORTS_PROFIT_GRAPH_SEGMENTS = exports.REPORTS_DATE_BUTTONS = exports.GENERAL_SETTINGS = exports.ENTRY_TYPE_CURRENCY = exports.DAYS_YEAR = exports.DAYS_QUARTER = exports.DAYS_MONTH = exports.DAYS_WEEK = exports.STRIPE_PRODUCT_ID_MONTHLY = exports.CLOUD_FUNCTIONS_DOMAIN_NAME = exports.PROJECT_ID = exports.APP_NAME = void 0;

var models_1 = require("./models");

exports.APP_NAME = 'chronos';
exports.PROJECT_ID = 'chronos-7927c';
exports.CLOUD_FUNCTIONS_DOMAIN_NAME = "https://us-central1-".concat(exports.PROJECT_ID, ".cloudfunctions.net");
exports.STRIPE_PRODUCT_ID_MONTHLY = 'price_1H0H1NHwGtWhdJ1iQfYJSt1J';
exports.DAYS_WEEK = 7;
exports.DAYS_MONTH = 30;
exports.DAYS_QUARTER = 92;
exports.DAYS_YEAR = 365;
exports.ENTRY_TYPE_CURRENCY = (_exports$ENTRY_TYPE_C = {}, _defineProperty(_exports$ENTRY_TYPE_C, models_1.ENTRY_TYPES.TIME, false), _defineProperty(_exports$ENTRY_TYPE_C, models_1.ENTRY_TYPES.EXPENSE, true), _defineProperty(_exports$ENTRY_TYPE_C, models_1.ENTRY_TYPES.PAYMENT, true), _exports$ENTRY_TYPE_C);
exports.GENERAL_SETTINGS = {
  DATE_FORMAT: [{
    label: 'MM/DD/YYYY',
    value: 'regular'
  }, {
    label: 'DD/MM/YYYY',
    value: 'international'
  }],
  NUMBER_FORMAT: [{
    label: '1,999.00',
    value: 'regular'
  }, {
    label: '1.999,00',
    value: 'international'
  }],
  TIME_FORMAT: [{
    label: 'Regular',
    value: 'regular'
  }, {
    label: 'Military',
    value: 'military'
  }]
};
exports.REPORTS_DATE_BUTTONS = [{
  label: '30 days',
  value: exports.DAYS_MONTH
}, {
  label: 'Quarter',
  value: exports.DAYS_QUARTER
}, {
  label: 'Year',
  value: exports.DAYS_YEAR
}];
exports.REPORTS_PROFIT_GRAPH_SEGMENTS = (_exports$REPORTS_PROF = {}, _defineProperty(_exports$REPORTS_PROF, exports.DAYS_MONTH, ['Week 1', 'Week 2', 'Week 3', 'Week 4']), _defineProperty(_exports$REPORTS_PROF, exports.DAYS_QUARTER, ['Month 1', 'Month 2', 'Month 3']), _defineProperty(_exports$REPORTS_PROF, exports.DAYS_YEAR, ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4']), _exports$REPORTS_PROF);