import React from 'react';
import styled from '@emotion/styled';

import {
  COLORS,
  BorderRadius,
  PRIMARY_COLOR,
} from '@chronos/constants';

import Icon from './Icon';


export interface SearchBarProps {
  query: string;
  setQuery: (queryString: string) => void;
  onClickFilterButton?: () => void;
  hasFilters?: boolean;
}

export default function SearchBar(props: SearchBarProps) {
  const { query, setQuery, onClickFilterButton, hasFilters } = props;

  const showFilterButton = typeof onClickFilterButton === 'function';

  const handleClickFilterButton = () => {
    if (typeof onClickFilterButton === 'function') {
      onClickFilterButton();
    }
  };

  return (
    <Container>
      <Icon
        name="search"
        color={COLORS.GRAY_LIGHT}
      />
      <StyledInput
        type="search"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {showFilterButton && (
        <Icon
          name="options"
          color={hasFilters ? PRIMARY_COLOR : COLORS.GRAY_LIGHT}
          onClick={handleClickFilterButton}
        />
      )}
    </Container>
  );
}

const Container = styled.div({
  backgroundColor: COLORS.WHITE,
  display: 'flex',
  position: 'relative',
  borderStyle: 'solid',
  borderColor: COLORS.GRAY_LIGHT,
  borderWidth: 1,
  padding: '0 20px',
  borderRadius: BorderRadius,
  alignItems: 'center',
});

const StyledInput = styled.input({
  width: 270,
  fontSize: 16,
  justifyContent: 'center',
  flex: 1,
  border: 0,
  color: COLORS.BLACK,
  padding: 16,
  backgroundColor: COLORS.TRANSPARENT,
});
