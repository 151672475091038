import React, { useState, ReactNode } from 'react';
import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

import Icon from '../components/Icon';
import Text from '../components/Text';
import { Spacer } from '.';

export interface CollapseProps {
  open?: boolean;
  children: ReactNode;
  label: string | JSX.Element;
  maxHeight?: number | string;
  className?: string;
  containerClassName?: string;
  expanderClassName?: string;
  onToggleOpen?: (isOpen: boolean) => void;
}

export default function Collapse({
  open = false, children, label, maxHeight, className, containerClassName, expanderClassName, onToggleOpen,
}: CollapseProps) {
  const [isOpen, setIsOpen] = useState(open);
  const handleExpand = () => {
    if (onToggleOpen) {
      onToggleOpen(!isOpen);
    }

    setIsOpen(!isOpen)
  };

  return (
    <Container className={[className, containerClassName].filter((x) => x).join(' ')}>
      <LabelContainer onClick={handleExpand}>
        <StyledText as="h2">{label}</StyledText>
        <Spacer size={16} />
        <StyledIcon open={isOpen} size={24} name="arrow-forward" ios />
      </LabelContainer>

      {isOpen && (
        <Expand maxHeight={maxHeight} className={expanderClassName}>
          {children}
        </Expand>
      )}
    </Container>
  );
}


const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderBottom: `1px solid ${COLORS.GRAY_LIGHT}`,
});

const StyledText = styled(Text)({
  flex: 1,
});

interface StyledIconProps {
  open: boolean;
}

const StyledIcon = styled(Icon)<StyledIconProps>({
  transition: 'transform 400ms',
},
({ open }) => (open ? {
  transform: 'rotate(90deg)',
} : null));

const LabelContainer = styled.div({
  padding: 16,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
});

interface ExpandProps {
  maxHeight: CollapseProps['maxHeight'];
}

const Expand = styled.div<ExpandProps>({
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: 25,
  width: '100%',
},
({ maxHeight }) => ({
  maxHeight,
}));
