"use strict";

var _defineProperty = require("/mnt/c/Chronos/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$ENTRY_TYPE_I, _exports$ENTRY_TYPE_A, _exports$ENTRY_TYPE_D, _exports$ENTRY_TYPE_A2, _exports$BILLING_TYPE;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FILTER_DUE_DATE_BUTTONS = exports.FILTER_DATE_CREATED_BUTTONS = exports.BILLING_TYPE_AMOUNT_LABELS = exports.ENTRY_TYPE_AMOUNT_PLACEHOLDERS = exports.ENTRY_TYPE_DESCRIPTION_PLACEHOLDERS = exports.ENTRY_TYPE_AMOUNT_LABELS = exports.ENTRY_TYPE_ICONS = void 0;

var models_1 = require("./models");

exports.ENTRY_TYPE_ICONS = (_exports$ENTRY_TYPE_I = {}, _defineProperty(_exports$ENTRY_TYPE_I, models_1.ENTRY_TYPES.TIME, 'md-alarm'), _defineProperty(_exports$ENTRY_TYPE_I, models_1.ENTRY_TYPES.EXPENSE, 'md-paper'), _defineProperty(_exports$ENTRY_TYPE_I, models_1.ENTRY_TYPES.PAYMENT, 'md-cash'), _exports$ENTRY_TYPE_I);
exports.ENTRY_TYPE_AMOUNT_LABELS = (_exports$ENTRY_TYPE_A = {}, _defineProperty(_exports$ENTRY_TYPE_A, models_1.ENTRY_TYPES.TIME, 'Time spent (HRS)'), _defineProperty(_exports$ENTRY_TYPE_A, models_1.ENTRY_TYPES.EXPENSE, 'Amount spent'), _defineProperty(_exports$ENTRY_TYPE_A, models_1.ENTRY_TYPES.PAYMENT, 'Amount paid'), _exports$ENTRY_TYPE_A);
exports.ENTRY_TYPE_DESCRIPTION_PLACEHOLDERS = (_exports$ENTRY_TYPE_D = {}, _defineProperty(_exports$ENTRY_TYPE_D, models_1.ENTRY_TYPES.TIME, 'What did you get done?'), _defineProperty(_exports$ENTRY_TYPE_D, models_1.ENTRY_TYPES.EXPENSE, 'What did you spend money on?'), _defineProperty(_exports$ENTRY_TYPE_D, models_1.ENTRY_TYPES.PAYMENT, 'What did you get paid for?'), _exports$ENTRY_TYPE_D);
exports.ENTRY_TYPE_AMOUNT_PLACEHOLDERS = (_exports$ENTRY_TYPE_A2 = {}, _defineProperty(_exports$ENTRY_TYPE_A2, models_1.ENTRY_TYPES.TIME, 'How long did you spend on this?'), _defineProperty(_exports$ENTRY_TYPE_A2, models_1.ENTRY_TYPES.EXPENSE, 'How much money this cost?'), _defineProperty(_exports$ENTRY_TYPE_A2, models_1.ENTRY_TYPES.PAYMENT, 'How much did the client pay you?'), _exports$ENTRY_TYPE_A2);
exports.BILLING_TYPE_AMOUNT_LABELS = (_exports$BILLING_TYPE = {}, _defineProperty(_exports$BILLING_TYPE, models_1.BILLING_TYPES.FIXED_FEE, 'Fee amount'), _defineProperty(_exports$BILLING_TYPE, models_1.BILLING_TYPES.TIME_MATERIALS, 'Hourly rate'), _defineProperty(_exports$BILLING_TYPE, models_1.BILLING_TYPES.NON_BILLABLE, 'Amount'), _exports$BILLING_TYPE);
exports.FILTER_DATE_CREATED_BUTTONS = [{
  label: '7 days',
  value: 7
}, {
  label: '1 month',
  value: 30
}, {
  label: '1 quarter',
  value: 92
}];
exports.FILTER_DUE_DATE_BUTTONS = [{
  label: 'Past due',
  value: -1
}, {
  label: '7 days',
  value: 7
}, {
  label: '1 month',
  value: 30
}];