import React, { useEffect } from 'react';

import { auth } from '@chronos/firebase';
import { SUBSCRIPTION_STATUS_TYPES } from '@chronos/constants';

import { useUser, useBus } from '../state';
import { Text } from '../components';

export default function useGettingStarted() {
  const { user } = useUser();
  const { banner, config } = useBus();

  useEffect(() => {
    const showUpgradeModal = !config.WEB_MVP
      && ((user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.TRIAL
        && user?.subscription?.trial?.endDate.toDate
        && user?.subscription?.trial?.endDate.toDate() < new Date())
        || user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.EXPIRED
        || user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.CANCELLED);

    if (!showUpgradeModal
      && user?.preferences.onboardingComplete === true
      && user?.subscription?.trial?.gettingStarted === false) {
      const onClose = () => auth.updateUser(user.uid, {
        // TODO: allow for string partial updates in typescript
        'subscription.trial.gettingStarted': true,
      } as any);

      banner({
        message: (
          <Text>Welcome to Chronos! Have questions? Check out our&nbsp;
            <a
              target="_blank"
              onClick={() => { banner(null); onClose(); }}
              rel="noopener noreferrer"
              href="https://help.chronostimetracking.com/getting-started"
            >
                getting started guide
            </a>.
          </Text>
        ),
        onClose,
      });
    }
  }, [user]); // eslint-disable-line
}
