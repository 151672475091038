import React, { useState, ChangeEvent, forwardRef, useRef } from 'react';
import styled from '@emotion/styled';
import { $Values } from 'utility-types';

import { PRIMARY_COLOR, COLORS } from '@chronos/constants';

import Icon from './Icon';
import Text from './Text';
import { InputLabel } from './InputLabel';

export const InputPattern = Object.freeze({
  Email: 'email' as 'email',
});
export type InputPattern = $Values<typeof InputPattern>;

const InputPatterns = {
  [InputPattern.Email]: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
};

const getValueByType = (value: any, type: InputProps['type']) => {
  if (type === 'number') {
    if (value === '') {
      return '';
    }

    return Number(value);
  }

  // TODO: Handle more type coercions
  return value;
};

export type InputProps = JSX.IntrinsicElements['input'] & JSX.IntrinsicElements['textarea'] & {
  as?: string;
  label?: string;
  width?: string | number;
  height?: string | number;
  patternType?: InputPattern;
  light?: boolean;
  focused?: boolean;
  hasError?: boolean;
  textarea?: boolean;
  dropdown?: boolean;
  clearable?: boolean;
  prepend?: string;
  requiredMark?: boolean;
  onClear?: () => void;
  onChangeValue?: (value: string) => void;
  containerClassName?: string;
}

export default forwardRef<HTMLInputElement|HTMLTextAreaElement, InputProps>(({
  label,
  pattern,
  type = 'text',
  width = '100%',
  light,
  focused,
  hasError,
  onChange,
  textarea,
  onChangeValue,
  dropdown,
  clearable,
  onClear,
  prepend,
  requiredMark,
  containerClassName,
  ...rest
}, outerRef) => {
  const ref = outerRef ?? useRef();
  const [visiblePassword, setvisiblePassword] = useState(false);
  const isPassword = type === 'password';
  const pseudoType = visiblePassword ? 'text' : 'password';

  const id = (label ?? '__fake__id__').toLowerCase();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    } else if (onChangeValue) {
      const value = getValueByType(e.target.value, type);
      onChangeValue(value);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    if (onChangeValue) {
      onChangeValue('');
    }
  };

  const renderIcon = () => {
    if (isPassword) {
      return (
        <FloatingIcon>
          <Icon
            hitSlop={4}
            name={visiblePassword ? 'eye-off' : 'eye'}
            color={light ? COLORS.WHITE : rest.color}
            onClick={() => setvisiblePassword(!visiblePassword)}
          />
        </FloatingIcon>
      );
    }

    if (dropdown && !isPassword) {
      return (
        <FloatingIcon>
          <Icon
            ios
            color={light ? COLORS.WHITE : rest.color}
            // @ts-ignore
            onClick={() => ref?.current?.focus()}
            name="arrow-down"
          />
        </FloatingIcon>
      );
    }

    if (clearable && rest.value !== undefined && rest.value !== '') {
      return (
        <FloatingIcon>
          <Icon
            color={light ? COLORS.WHITE : rest.color}
            onClick={handleClear}
            name="close-circle-outline"
          />
        </FloatingIcon>
      );
    }
  };

  return (
    <Container width={width} className={containerClassName}>
      <InputLabel as="label" mark={requiredMark} light={light} htmlFor={id}>{label}</InputLabel>
      <InputContainer>
        {!!prepend && (
          // @ts-ignore
          <PrependText vCenter {...rest}>{prepend}</PrependText>
        )}
        <StyledInput
          {...rest}
          id={id}
          // @ts-ignore
          ref={ref}
          as={textarea ? 'textarea' : undefined}
          pattern={pattern && InputPatterns[pattern]}
          isPassword={isPassword}
          focused={focused}
          hasError={hasError}
          textarea={textarea}
          dropdown={dropdown}
          type={isPassword ? pseudoType : type}
          light={light}
          onChange={handleOnChange}
          prepend={prepend}
        />
        {renderIcon()}
      </InputContainer>
    </Container>
  );
});

interface ContainerProps {
  width: string | number;
}

const Container = styled.div<ContainerProps>({
  display: 'flex',
  flexDirection: 'column',
},
({ width }) => ({
  width,
}));

const SL = styled.label`
  font-size: 14px;
  font-weight: 400;
  user-select: none;

  &:after {
    content: '*';
    color: ${COLORS.RED};
  }
`;

const InputContainer = styled.div({
  position: 'relative',
});

const FloatingIcon = styled.div({
  position: 'absolute',
  right: 0,
  bottom: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  marginRight: 10,
});

const PrependText = styled(Text)({
  position: 'absolute',
  alignItems: 'center',
  left: 0,
  top: 0,
  bottom: 0,
});

interface StyledInputProps {
  isPassword: boolean;
  light?: boolean;
  textarea?: boolean;
  focused?: boolean;
  hasError?: boolean;
  height?: string | number;
  dropdown?: boolean;
  readOnly?: boolean;
  prepend?: string;
}

const StyledInput = styled.input<StyledInputProps>({
  fontSize: 14,
  borderBottomStyle: 'solid',
  borderBottomColor: COLORS.GRAY_LIGHT,
  borderBottomWidth: 1,
  justifyContent: 'center',
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  width: '100%',
  color: COLORS.BLACK,
  padding: 8,
  boxShadow: 'none',
  backgroundColor: COLORS.TRANSPARENT,
},
({ isPassword, light, focused, hasError, textarea, height, dropdown, readOnly, prepend = '' }) => ({
  ':focus': {
    borderBottomColor: PRIMARY_COLOR,
  },
  paddingRight: isPassword ? 50 : 8,
  ...(textarea && {
    resize: 'vertical',
    minHeight: 20 * 4,
  }),
  ...(prepend && {
    paddingLeft: prepend.length * 5 + 8,
  }),
  ...(height && {
    minHeight: height,
  }),
  ...((dropdown && readOnly) && {
    cursor: 'pointer',
  }),
  ...(light && {
    color: COLORS.WHITE,
    ':focus': {
      borderBottomColor: COLORS.WHITE,
    },
    '::-ms-input-placeholder': {
      color: COLORS.GRAY_LIGHTER,
    },
    ':-ms-input-placeholder': {
      color: COLORS.GRAY_LIGHTER,
    },
    '::placeholder': {
      color: COLORS.GRAY_LIGHTER,
    },
  }),
  ...(focused && { borderBottomColor: PRIMARY_COLOR }),
  ...(hasError && {
    borderBottomColor: COLORS.RED,
    ':focus': {
      borderBottomColor: COLORS.RED,
    },
  }),
}));
