import React from 'react';
import styled from '@emotion/styled';

import { COLORS, BorderRadius } from '@chronos/constants';

import Text from './Text';

export interface ErrorMessageProps {
  children?: string;
}

export default function ErrorMessage({ children }: ErrorMessageProps) {
  if (children) {
    return (
      <Container>
        <Text center bold color={COLORS.RED}>{children}</Text>
      </Container>
    );
  }

  return null;
}

const Container = styled.div({
  borderLeft: `6px solid ${COLORS.RED}`,
  backgroundColor: `${COLORS.RED}15`,
  margin: 8,
  padding: 16,
  borderRadius: BorderRadius,
});
