import React from 'react';
import { Global, css } from '@emotion/core';

import { COLORS } from '@chronos/constants';

export const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        height: 100vh;
      }

      body, #root {
        height: 100%;
        background-color: ${COLORS.GRAY_BACKGROUND};

        .modal-overlay {
          filter: blur(2px);
        }
      }

      h1, h2, h3, span, p, label {
        margin: 0;
      }

      a {
        color: ${COLORS.CYAN};
      }

      * {
        box-sizing: border-box;
        outline: 0;
      }

      .invisible {
        visibility: hidden;
      }

      input {
        box-shadow: 'none',
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      /* Remove clear button on inputs */
      input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
      input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration { display: none; }
    `}
  />
);
