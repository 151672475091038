import { useState } from 'react';
import createUseContext from 'constate';
import { User as FirebaseUser } from 'firebase';

export default createUseContext(() => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

  return {
    firebaseUser,
    setFirebaseUser,
  };
}, (value) => [value.firebaseUser]);
