import { $Values } from 'utility-types';

import serverEpochTimeOffset from './serverEpochTimeOffset';
import user from './user';
import firebaseUser from './firebaseUser';
import projects from './projects';
import projectTypes from './projectTypes';
import filters from './filters';
import bus from './bus';

// Providers
export const ServerEpochTimeOffsetProvider = serverEpochTimeOffset.Provider;
export const UserProvider = user.Provider;
export const FirebaseUserProvider = firebaseUser.Provider;
export const ProjectsProvider = projects.Provider;
export const ProjectTypesProvider = projectTypes.Provider;
export const FiltersProvider = filters.Provider;
export const BusProvider = bus.Provider;

// Hooks
export const useServerEpochTimeOffset = serverEpochTimeOffset;
export const useUser = user;
export const useFirebaseUser = firebaseUser;
export const useProjects = projects;
export const useProjectTypes = projectTypes;
export const useProjectFilters = filters;
export const useBus = bus;

export const useAll = () => ({
  ...serverEpochTimeOffset(),
  ...user(),
  ...projects(),
  ...projectTypes(),
  ...firebaseUser(),
  ...filters(),
  ...bus(),
});

export type UseAll = $Values<typeof useAll>;

export const useAllStates = () => {
  const {
    hasFilters,
    filterArchived,
    filterProjectType,
    filterDateCreated,
    filterDueDate,
  } = filters();

  return {
    serverEpochTimeOffset: serverEpochTimeOffset().serverEpochTimeOffset,
    user: user().user,
    projects: projects().projects,
    projectTypes: projectTypes().projectTypes,
    firebaseUser: firebaseUser().firebaseUser,
    bus: bus(),
    filters: {
      hasFilters,
      filterArchived,
      filterProjectType,
      filterDateCreated,
      filterDueDate,
    },
  };
};
