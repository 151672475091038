import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { $Values } from 'utility-types';

import { renderElement } from '@chronos/utils';
import { COLORS, BorderRadius, Shadows } from '@chronos/constants';

import { useBus } from '../state';
import Icon from './Icon';
import Text from './Text';
import media from '../shared/media';

export const BannerType = Object.freeze({
  info: 'info' as 'info',
  error: 'error' as 'error',
  success: 'success' as 'success',
});
export type BannerType = $Values<typeof BannerType>;

export interface BannerOptions {
  message: string | ReactNode | (() => JSX.Element);
  type?: BannerType;
  /**
   * Is the banner closable
   */
  closable?: boolean;
  /**
   * Timeout in milliseconds
   */
  timeout?: number;
  /**
   * Called when user closes modal
   */
  onClose?: () => void;
}

interface BannerProps {
  showSideNav: boolean;
  showTopNav: boolean;
}

export default function Banner({ showSideNav, showTopNav }: BannerProps) {
  const { bannerOptions, banner } = useBus();

  if (!bannerOptions) {
    return null;
  }

  const {
    type = BannerType.info,
    closable = true,
    timeout = 60 * 1000,
    onClose,
  } = bannerOptions;

  const handleClick = () => {
    banner(null);

    if (onClose) {
      onClose();
    }
  };

  if (timeout) {
    setTimeout(() => banner(null), timeout);
  }

  const renderContent = (message: any) => {
    if (type === BannerType.error) {
      return (
        <Flex>
          {typeof message === 'string' ? <Text bold color={COLORS.RED}>{message}</Text> : message}
        </Flex>
      );
    }

    return (
      <Flex>
        {typeof message === 'string' ? <Text>{message}</Text> : message}
      </Flex>
    );
  };

  const message = renderElement(bannerOptions.message);

  return (
    <Fixed showSideNav={showSideNav} showTopNav={showTopNav}>
      <Container>
        {type === BannerType.info && <Icon name="information" />}
        {type === BannerType.error && <Icon color={COLORS.RED} name="warning" />}
        {type === BannerType.success && <Icon color={COLORS.TEAL} name="checkmark-circle-outline" />}

        {renderContent(message)}

        <Icon color={closable ? undefined : COLORS.TRANSPARENT} onClick={handleClick} name="close" />
      </Container>
    </Fixed>
  );
}

interface FixedProps {
  showSideNav: boolean;
  showTopNav: boolean;
}

const Fixed = styled.div<FixedProps>({
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  padding: '0 10%',
},
({ showSideNav, showTopNav }) => ({
  width: '100vw',
  ...(showSideNav && {
    marginLeft: '80px',

    [media('small')]: {
      marginLeft: 0,
    },
  }),
  ...(showTopNav && { marginTop: '61px' }),
}));

const Flex = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Container = styled.div({
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.WHITE,
  borderBottomLeftRadius: BorderRadius,
  borderBottomRightRadius: BorderRadius,
  boxShadow: Shadows.S1(),
});
