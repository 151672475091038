import React from 'react';
import styled from '@emotion/styled';
import { Field, FieldConfig, FieldProps } from 'formik';

import { COLORS } from '@chronos/constants';

import Input, { InputProps } from '../Input';
import Text from '../Text';

type FormInputProps = Pick<FieldConfig, 'children' | 'validate' | 'name'> & InputProps;

export default function FormInput({
  className,
  name,
  validate,
  ...props
}: FormInputProps) {
  return (
    <div className={className}>
      <Field name={name} validate={validate}>
        {({ field, meta }: FieldProps) => (
          <InputWrapper>
            <Input
              {...field}
              {...props}
              ref={undefined}
              hasError={Boolean(meta.error)}
            />
            <ErrorMessage>
              <Text semibold color={COLORS.RED}>{meta.error}</Text>
            </ErrorMessage>
          </InputWrapper>
        )}
      </Field>
    </div>
  );
}

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const ErrorMessage = styled.div({
  padding: 6,
  width: '100%',
});
