import React, { useState } from 'react';
import styled from '@emotion/styled';
import media from '../shared/media';

export default class PageBottomBannerAd extends React.Component<propTypes>{
  componentDidMount() {
    (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
  }

  render() {
    const { showSideNav } = this.props;

    return (
      <BottomBannerAdContainer showSideNav={showSideNav}>
        <ins className="adsbygoogle"
          style={{ display: 'block', width: '100%', height: '100%' }}
          data-ad-layout-key="-fb+5w+4e-db+86"
          data-ad-client="ca-pub-2594926552837618"
          data-ad-slot="3537449473"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </BottomBannerAdContainer>
    );
  }
}

interface propTypes {
  showSideNav: boolean;
}

const BottomBannerAdContainer = styled.div<{
  showSideNav: boolean;
}>({
  width: 'calc(100% - 10px)',
  minHeight: '300px',
  marginBottom: '4px',
  alignSelf: 'center',
}, ({ showSideNav }) => ({
  ...(showSideNav && { width: 'calc(100% - 80px)' }),
}));
