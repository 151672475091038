import { createPortal } from 'react-dom';
import React, { useState, ReactNode, CSSProperties } from 'react';
import { Manager, Reference, Popper, PopperProps } from 'react-popper';
import styled from '@emotion/styled';

import { COLORS, Shadows } from '@chronos/constants';

export type PopoverProps = PopperContentProps & {
  target: JSX.Element | ReactNode;
  children: ReactNode;
  placement?: PopperProps['placement'];
  style?: CSSProperties;
  offset?: number;
}

export default function Popover({ children, target, placement = 'bottom', padding, style, offset = 6 }: PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
    document.removeEventListener('click', close);
  };

  const handleOpen = (shouldOpen: boolean) => {
    if (shouldOpen) {
      setIsOpen(true);
      document.addEventListener('click', close);
    } else {
      close();
    }
  };
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TargetContent
            onClick={() => {
              handleOpen(!isOpen);
            }}
            ref={ref}
          >
            {target}
          </TargetContent>
        )}
      </Reference>
      {
        createPortal(
          <Popper
            placement={placement}
            modifiers={{
              offset: {
                fn: (data) => {
                  /* eslint-disable no-param-reassign */
                  if (data.placement === 'top') {
                    data.offsets.popper.top -= offset;
                  } else if (data.placement === 'bottom') {
                    data.offsets.popper.top += offset;
                  } else if (data.placement === 'left') {
                    data.offsets.popper.left -= offset;
                  } else if (data.placement === 'right') {
                    data.offsets.popper.top += offset;
                  }
                  /* eslint-enable no-param-reassign */

                  return data;
                },
              },
            }}
          >
            {(popperProps) => (isOpen ? (
              <PopperContent
                {...popperProps}
                onClick={(e) => {
                  e.nativeEvent.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  handleOpen(false);
                }}
                padding={padding}
                style={{
                  ...popperProps.style,
                  ...(style && style),
                }}
                data-placement={popperProps.placement}
              >
                {children}
              </PopperContent>
            ) : null)}
          </Popper>,
          // TODO: fix to have fallback if popover doesn't exist
          document.querySelector('#popover')!,
        )
      }

    </Manager>
  );
}

interface PopperContentProps {
  padding?: number;
}

const PopperContent = styled.div<PopperContentProps>({
  backgroundColor: COLORS.WHITE,
  zIndex: 900,
  borderRadius: 8,
  boxShadow: Shadows.Popover,
}, ({ padding = 20 }) => ({
  padding,
}));

const TargetContent = styled.div({
  cursor: 'pointer',
});
