import React from 'react';
import AffiliateSignupCompanionFreelancingFreedom from './AffiliateSignupCompanionFreelancingFreedom';
import AffiliateSignupTopCompanionFreelancingFreedom from './AffiliateSignupTopCompanionFreelancingFreedom';

export const affiliateLinkPaths = ['/freelancingfreedom'];

const affiliateLoginTopCompanionComponentMap = {
  '/freelancingfreedom': <AffiliateSignupTopCompanionFreelancingFreedom />,
};

export const getAffiliateSignupTopCompanion = (pathname: string) => {
  const matchingComponent = affiliateLoginTopCompanionComponentMap[pathname];

  if (matchingComponent) {
    return matchingComponent;
  }

  return null;
};

const affiliateLoginCompanionComponentMap = {
  '/freelancingfreedom': <AffiliateSignupCompanionFreelancingFreedom />,
};

export const getAffiliateSignupCompanion = (pathname: string) => {
  const matchingComponent = affiliateLoginCompanionComponentMap[pathname];

  if (matchingComponent) {
    return matchingComponent;
  }

  return null;
};
