"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialAuthType = exports.Collection = void 0;
exports.Collection = Object.freeze({
  Users: 'users',
  Projects: 'projects',
  ProjectTypes: 'projectTypes',
  Entries: 'entries'
});
exports.SocialAuthType = {
  Apple: 'Apple',
  Google: 'Google'
};