import React from 'react';
import styled from '@emotion/styled';

import { APP_NAME, PRIMARY_COLOR } from '@chronos/constants';
import { ChronosGradientIcon } from '../shared/icons';

export interface LogoProps {
  text?: boolean;
  size?: number | string;
}

export default ({ text, size }: LogoProps) => {
  if (text) {
    return <TextLogo size={size}>{APP_NAME}</TextLogo>;
  }

  return <ChronosGradientIcon width={size} height={size} className="title-chronos-icon" color="#6713D2" />
};

const StyledImage = styled.img({
  userSelect: 'none',
});

interface TextLogoProps {
  size?: number | string;
}

const TextLogo = styled.div<TextLogoProps>({
  textTransform: 'uppercase',
  color: PRIMARY_COLOR,
  fontWeight: 300,
  userSelect: 'none',
},
({ size = 20 }) => ({
  fontSize: size,
}));
