/* eslint-disable @typescript-eslint/camelcase */

import React, { useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { Formik, FormikProps, Form, FormikHelpers } from 'formik';
import styled from '@emotion/styled';
import { $Values } from 'utility-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import amplitude from 'amplitude-js';

import {
  COLORS,
  PRIMARY_COLOR,
  CLOUD_FUNCTIONS_DOMAIN_NAME,
  STRIPE_PRODUCT_ID_MONTHLY,
} from '@chronos/constants';
import { analytics } from '@chronos/firebase';

import { Button, Text, ErrorMessage, Spacer } from '../../components';
import { useUser, useBus } from '../../state';
import FormInput from '../../components/Fom/FormInput';
import { subscriptionAmountOverPeriod } from '../../shared/constants';

const baseInputStyles = {
  base: {
    color: COLORS.BLACK,
    fontFamily: '\'Montserrat\', \'Helvetica Neue\', sans-serif',
    fontSize: '14px',
  },
};

export const SubsciptionType = Object.freeze({
  Annually: 'Annually' as 'Annually',
  Monthly: 'Monthly' as 'Monthly',
});

export const PlanPrice = Object.freeze({
  Annually: 119.99,
  Monthly: 11.99,
});

export type SubsciptionType = $Values<typeof SubsciptionType>;
export type PlanPrice = $Values<typeof PlanPrice>;

export interface CheckoutFormProps {
  // type?: SubsciptionType;
  onSuccess: () => void;
}

interface Address {
  city: string;
  state: string;
  line1: string;
  postal_code: string;
}

interface Owner {
  email: string;
  name: string;
  address: Address;
}

type FormValues = Pick<Owner, 'address'> & {
  firstName: string;
  lastName: string;
  // plan: SubsciptionType;
}

const formSchema = yup.object().shape({
  // plan: yup.mixed().oneOf(Object.keys(SubsciptionType)),
  firstName: yup.string().trim().required('Required').min(1),
  lastName: yup.string().trim().required('Required').min(1),
  address: yup.object().shape({
    city: yup.string().trim().required('Required'),
    state: yup.string().trim().required('Required'),
    line1: yup.string().trim().required('Required'),
    postal_code: yup.string().trim().required('Required'),
  }).required(),
});

export default injectStripe<CheckoutFormProps>(({
  // type = SubsciptionType.Monthly,
  stripe,
}) => {
  const { user } = useUser();
  const { modal, banner } = useBus();

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  let isdirty = false;

  const handleCheckout = async ({
    // plan,
    firstName,
    lastName,
    address,
  }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    if (!user) {
      throw new Error('No authenticated user');
    }

    setLoading(true);

    const {
      email,
      uid,
    } = user;

    const name = `${firstName} ${lastName}`;

    try {
      const response = await stripe?.createSource({
        type: 'card',
        owner: {
          email,
          name,
          address,
        },
      });

      // "MONTHLY" to match data from RevenueCat.
      const plan = 'MONTHLY';

      await axios.post(`${CLOUD_FUNCTIONS_DOMAIN_NAME}/stripeSubscribe`, {
        uid,
        plan,
        email,
        source: response?.source?.id,
      });

      const mrrUsdPrice = 9.00;

      const revenue = new amplitude.Revenue()
        .setProductId(STRIPE_PRODUCT_ID_MONTHLY)
        .setPrice(mrrUsdPrice)
        .setQuantity(1)
        .setRevenueType(plan);

      analytics.logEvent('sign up - success : purchase completed', {
        // type: plan,
        industry: user.industry,
        $mrr: mrrUsdPrice,
      });

      amplitude.getInstance().logRevenueV2(revenue);

      formikHelpers.resetForm();

      // We had a success. Don't ask the user if they are OK with the modal closing.
      modal.setConfirm(false);

      modal.pop();
      banner({ message: 'Thanks for your purchase! You\'ll receive a purchase confirmation email momentarily.' });
    } catch (error) {
      if (error.response.data?.raw?.message) {
        setErrorMessage(error.response.data.raw.message);
      } else if (error.response.data) {
        setErrorMessage(error.response.data);
      } else {
        setErrorMessage('An error occured when processing your transation. Please try again.');
      }

      analytics.logEvent('sign up - error : purchase failed', { error: JSON.stringify(error) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik<FormValues>
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      initialValues={{
        // plan: type,
        firstName: '',
        lastName: '',
        address: {
          line1: '',
          city: '',
          state: '',
          postal_code: '',
        },
      }}
      onSubmit={handleCheckout}
      render={(props: FormikProps<FormValues>) => {
        if (isdirty !== props.dirty) {
          isdirty = props.dirty;
          modal.setConfirm(props.dirty);
        }

        return (
          <Form>
            <Container>
              <ErrorMessage>{errorMessage}</ErrorMessage>

              <Price>
                <Text color={COLORS.PURPLE} as="h3" bold>{subscriptionAmountOverPeriod}</Text>
                {/* <Text color={COLORS.PURPLE} as="h3" bold>{props.values.plan === SubsciptionType.Monthly ? `$${PlanPrice.Monthly}/month` : `$${PlanPrice.Annually}/year`}</Text> */}
              </Price>

              {/* <SelectRow>
                <FormSelect<{ label: string; value: string }>
                  bvo
                  name="plan"
                  label="Subscription type"
                  options={[
                    {
                      label: 'Annual Subscription',
                      value: SubsciptionType.Annually,
                    },
                    {
                      label: 'Monthly Subscription',
                      value: SubsciptionType.Monthly,
                    },
                  ]}
                />
              </SelectRow> */}

              <Row>
                <FormInput name="firstName" label="First Name" />
                <FormInput name="lastName" label="Last Name" />
              </Row>

              <FormInput name="address.line1" label="Address" />

              <Row>
                <FormInput name="address.city" label="City" />

                <Row>
                  <FormInput name="address.state" label="State" />
                  <FormInput name="address.postal_code" label="Zip" />
                </Row>
              </Row>

              <TextContainer>
                <Text size={14} weight={400} noSelect as="label">Credit Card</Text>
                <StyledCardElement style={baseInputStyles} id="card-number" />
              </TextContainer>

              <Spacer />
              <Button width="calc(100% - 16px)" type="submit" primary loading={loading} onClick={() => undefined}>Subscribe</Button>
            </Container>
          </Form>
        );
      }}
    />
  );
});

const Container = styled.div({
  display: 'flex',
  maxWidth: 400,
  flexDirection: 'column',
  '& >:not(:last-child)': {
    marginBottom: 20,
  },
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Price = styled.div();

const SelectRow = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  '& >:not(:last-child)': {
    marginRight: 20,
  },
  '& >:first-of-type': {
    flex: 1,
  },
});

const Row = styled.div({
  display: 'flex',
  '&:not(.no-flex) > *': {
    flex: 1,
  },
  '& >:not(:last-child)': {
    marginRight: 20,
  },
});

const inputStyles = {
  fontSize: 20,
  borderBottomStyle: 'solid',
  borderBottomColor: COLORS.GRAY_LIGHT,
  borderBottomWidth: 1,
  justifyContent: 'center',
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  width: '100%',
  color: COLORS.BLACK,
  padding: 16,
  backgroundColor: COLORS.TRANSPARENT,
  ':focus': {
    borderBottomColor: PRIMARY_COLOR,
  },
} as any;

const StyledCardElement = styled(CardElement)(inputStyles);
