import React from 'react';
import { Elements } from 'react-stripe-elements';

import CheckoutForm, { CheckoutFormProps } from './CheckoutForm';
import { ModalOptions } from '../../components/Modal/Modal';

export default function Checkout(props: CheckoutFormProps) {
  return (
    <Elements>
      <CheckoutForm {...props} />
    </Elements>
  );
}

export const getCheckoutModalOptions = (props: CheckoutFormProps, noClose?: boolean): ModalOptions => ({
  noClose,
  confirmPrompt: 'Closing will clear all form data. Are you sure you want to close?',
  heading: 'Checkout',
  children: <Checkout {...props} />,
});
