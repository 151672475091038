import React, { MouseEventHandler } from 'react';
import styled, { Interpolation } from '@emotion/styled';

import { COLORS } from '@chronos/constants';

export type TextButtonProps = JSX.IntrinsicElements['span'] & StyledTextButtonProps & {
  text: string;
  light?: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler;
}

export default ({
  text,
  onClick,
  primary,
  secondary,
  disabled,
  light,
  lg,
  md,
  sm,
  hidden,
  ...rest
}: TextButtonProps) => {
  const handleClick: MouseEventHandler = (event) => {
    if (!disabled) {
      onClick(event);
    }
  };

  return (
    <StyledTextButton
      {...rest}
      disabled={disabled}
      light={light}
      primary={primary}
      secondary={secondary}
      lg={lg}
      md={md}
      sm={sm}
      hidden={hidden}
      onClick={handleClick}
    >
      {text}
    </StyledTextButton>
  );
};

interface StyledTextButtonProps {
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  light?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  hidden?: boolean;
  width?: string | number;
}

const StyledTextButton = styled.span<StyledTextButtonProps>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  userSelect: 'none',
  padding: 10,
  color: COLORS.PURPLE,
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8,
  },
},
({ secondary, light, lg, sm, disabled, width = '100%', hidden }) => {
  const styles: Interpolation<StyledTextButtonProps & { theme: object }> = {};

  if (secondary) {
    styles.color = COLORS.CYAN;
  } else if (light) {
    styles.color = COLORS.WHITE;
  }

  if (lg) {
    styles.fontSize = 24;
  } else if (sm) {
    styles.fontSize = 12;
  } else {
    styles.fontSize = 18;
  }

  if (disabled) {
    styles.opacity = 0.6;
    styles.cursor = 'not-allowed';
  }

  styles.width = width;

  if (hidden) {
    styles.visibility = 'hidden';
  }

  return styles;
});
