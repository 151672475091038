import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const BasicNavLink = styled(NavLink)((props) => ({
  // Don't specially color or underline the link text.
  color: 'initial',
  textDecoration: 'none',
  // This is dumb but the NavLink maintainers don't provide an alternative.
  // https://github.com/ReactTraining/react-router/issues/1082
  'pointer-events': props['is-disabled'] ? 'none' : '',
}));
