import { useState } from 'react';
import createUseContext from 'constate';
import { auth } from '@chronos/firebase';
import { User } from '@chronos/constants';

export default createUseContext(() => {
  const [user, setUser] = useState<User | null>(null);

  const updateUser = (newUser: User) => {
    if (user) {
      auth.updateUser(user.uid, {
        ...user,
        ...newUser,
      } as any);
    } else {
      setUser(newUser);
    }
  };

  return {
    user,
    setUser,
    updateUser,
  };
}, (value) => [value.user]);
