import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { $Values } from 'utility-types';

import { renderElement } from '@chronos/utils';

import Modal from '../Modal/Modal';
import Button from '../Button';
import TextButton from '../TextButton';
import { useBus } from '../../state';

export const ConfirmType = Object.freeze({
  info: 'info' as 'info',
  danger: 'danger' as 'danger',
});
export type ConfirmType = $Values<typeof ConfirmType>;

export interface ConfirmOptions {
  prompt: string | ReactNode | (() => JSX.Element);
  onClose: (confirmed: boolean) => void;
  heading?: string;
  type?: ConfirmType;
  confirmLabel?: string;
  cancelLabel?: string;
  hideOverlay?: boolean;
}

export default function Confirm() {
  const { confirmOptions, setConfirm } = useBus();

  if (confirmOptions === null) {
    return null;
  }

  const {
    prompt,
    heading,
    type = ConfirmType.danger,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    onClose,
    hideOverlay,
  } = confirmOptions;

  return (
    <Modal hideOverlay={hideOverlay} maxWidth={500} noClose heading={heading}>
      {renderElement(prompt)}
      <Buttons>
        <TextButton
          text={cancelLabel}
          onClick={() => {
            setConfirm(null);
            onClose(false);
          }}
        />
        <Spacer />
        <Button
          danger={type === ConfirmType.danger}
          onClick={() => {
            setConfirm(null);
            onClose(true);
          }}
        >
          {confirmLabel}
        </Button>
      </Buttons>
    </Modal>
  );
}

const Buttons = styled.div({
  marginTop: 20,
  display: 'flex',
  justifyContent: 'flex-end',
});

const Spacer = styled.div({
  width: 20,
});
