import React, { ReactNode } from 'react';
import styled, { Interpolation } from '@emotion/styled';
import CircleLoader from 'react-spinners/CircleLoader';

import { COLORS, PRIMARY_COLOR } from '@chronos/constants';

export type ButtonProps = JSX.IntrinsicElements['button'] & StyledButtonProps & {
  children: ReactNode;
  loading?: boolean;
  light?: boolean;
  onClick: () => void;
}

export default ({
  children,
  loading = false,
  disabled,
  onClick,
  danger,
  primary,
  secondary,
  type = 'button',
  width,
  light,
  lg,
  md,
  sm,
  hollow,
  ...rest
}: ButtonProps) => (
  <StyledButton
    {...rest}
    disabled={disabled || loading}
    hollow={hollow}
    type={type}
    light={light}
    width={width}
    danger={danger}
    primary={primary}
    secondary={secondary}
    lg={lg}
    md={md}
    sm={sm}
    onClick={onClick}
  >
    <Relative>
      <Absolute>
        <CircleLoader loading={loading} size={25} color={primary || secondary ? COLORS.WHITE : PRIMARY_COLOR} />
      </Absolute>
      {children}
    </Relative>
  </StyledButton>
);

const Relative = styled.div({
  position: 'relative',
});

const Absolute = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface StyledButtonProps {
  danger?: boolean;
  primary?: boolean;
  secondary?: boolean;
  light?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  hollow?: boolean;
  color?: string;
  width?: string | number;
  margin?: string | number;
  backgroundColor?: string;
  borderColor?: string;
}

const StyledButton = styled.button<StyledButtonProps>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  borderWidth: 1,
  borderColor: COLORS.TRANSPARENT,
  userSelect: 'none',
  cursor: 'pointer',
  padding: '12px 36px',
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.6,
  },
  ':not(:disabled) :hover': {
    opacity: 0.8,
  },
},
({ primary, secondary, lg, sm, light, color, width = '100%', danger, hollow, margin, backgroundColor, borderColor }) => {
  const styles: Interpolation<StyledButtonProps & { theme: object }> = {};

  styles.backgroundColor = COLORS.TRANSPARENT;

  if (danger) {
    styles.color = COLORS.WHITE;
    styles.backgroundColor = COLORS.RED;
  } else if (light) {
    styles.color = COLORS.CYAN;
    styles.backgroundColor = COLORS.WHITE;
  } else if (secondary) {
    styles.color = COLORS.WHITE;
    styles.backgroundColor = COLORS.CYAN;
  } else if (primary) {
    styles.color = COLORS.WHITE;
    styles.backgroundColor = COLORS.PURPLE;
  } else {
    styles.color = COLORS.PURPLE;
    styles.borderColor = COLORS.PURPLE;
  }

  if (color) {
    if (hollow) {
      styles.color = color;
      styles.borderColor = color;
      styles.backgroundColor = COLORS.TRANSPARENT;
    } else {
      styles.color = COLORS.WHITE;
      styles.backgroundColor = color;
    }
  }

  if (backgroundColor) {
    styles.backgroundColor = backgroundColor;
  }

  if (borderColor) {
    styles.borderColor = borderColor;
  }

  if (lg) {
    styles.fontSize = 20;
  } else if (sm) {
    styles.fontSize = 12;
  } else {
    styles.fontSize = 14;
  }

  styles.width = width;
  styles.margin = margin ?? 8;


  return styles;
});
