import React from 'react';
import { useSelector } from 'react-redux';
import { timeDisplay } from '@chronos/utils';

export default function TimeDisplay(props) {
  const dateText = timeDisplay(props.date, props.type);

  return (
    <>
      {dateText}
    </>
  );
}

export function isMobileBrowser(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
