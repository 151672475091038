import { useHistory } from 'react-router';

import { auth } from '@chronos/firebase';

import { useUser, useFirebaseUser } from '../state';

export default () => {
  const history = useHistory();
  const { setUser } = useUser();
  const { setFirebaseUser } = useFirebaseUser();

  return async () => {
    setUser(null);
    setFirebaseUser(null);
    await auth.logout();
    history.replace('/login');
  };
};
