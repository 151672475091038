import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { PRIMARY_COLOR } from '@chronos/constants';
import Logo from './Logo';
import Spacer from './Spacer';

export interface LadingProps {
  width?: string | number;
  height?: string | number;
}

const override = css`
  margin-right: 16px;
`;

export default (props: LadingProps) => (
  <Relative width-val={props.width} height-val={props.height}>
    <Logo />
    <Logo size={40} text />
    <Spacer />
    <PropagateLoaderContainer>
      <PropagateLoader css={override} color={PRIMARY_COLOR} />
    </PropagateLoaderContainer>
  </Relative>
);

const Relative = styled.div((props) => ({
  width: props['width-val'] || '100vw',
  height: props['height-val'] || '100vh',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: 0.7,
}));

// This container is helpful because the PropagateLoader has zero height because it uses
// absolute positioning.
const PropagateLoaderContainer = styled.div({
  minHeight: '18px',
});
