import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/analytics';

import amplitude from 'amplitude-js';

import { firebase as ChronosFirebase } from '@chronos/firebase';
import { PROJECT_ID } from '@chronos/constants';

import './index.css';
import {
  ServerEpochTimeOffsetProvider,
  UserProvider,
  FirebaseUserProvider,
  ProjectsProvider,
  ProjectTypesProvider,
  FiltersProvider,
  BusProvider,
} from './state';
import App from './App';

const API_KEY = process.env.NODE_ENV === 'development'
  ? '85ae60ff39b82aa794bf1b57492c9e56'
  : 'f32fafacecdce4f54258e33d4ea0c5e2';

amplitude.getInstance().init(API_KEY);

const firebaseConfig = {
  apiKey: 'AIzaSyCKGbHL2etPgiyZRIHe2GbgYor6e5XZH0c',
  authDomain: 'app.chronostimetracking.com',
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: '406913079545',
  appId: '1:406913079545:web:08e5d36225fe2d5a',
  measurementId: 'G-92PBC88B86',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true });
ChronosFirebase.init(firebase);

ReactDOM.render(
  <ServerEpochTimeOffsetProvider>
    <UserProvider>
      <FirebaseUserProvider>
        <StripeProvider apiKey="pk_live_eW4823ma4NKRNTU9NN08TdG100ci0r39YZ">
          <ProjectsProvider>
            <ProjectTypesProvider>
              <FiltersProvider>
                <BusProvider>
                  <Router>
                    <App />
                  </Router>
                </BusProvider>
              </FiltersProvider>
            </ProjectTypesProvider>
          </ProjectsProvider>
        </StripeProvider>
      </FirebaseUserProvider>
    </UserProvider>
  </ServerEpochTimeOffsetProvider>,
  document.getElementById('root'),
);
