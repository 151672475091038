import React from 'react';
import styled from '@emotion/styled';

import { COLORS, PRIMARY_COLOR, User } from '@chronos/constants';

import Icon from './Icon';
import Text from './Text';
import { useLogout } from '../hooks';
import PopoverMenu, { PopoverMenuOption } from './PopoverMenu';

export interface UserProfileProps {
  user: User | null;
}

export default ({ user }: UserProfileProps) => {
  const logout = useLogout();

  if (user) {
    const initial = user.firstName[0] || user.email[0];
    const options: PopoverMenuOption[] = [{
      label: 'Logout',
      danger: true,
      action: logout,
    }];

    return (
      <PopoverMenu options={options}>
        <Circle>
          {
            initial ? <Text as="h2" semibold light uppercase>{initial}</Text>
              : <Icon color={COLORS.WHITE} name="person" />
          }
          {
            (user.photoUrl && <Photo src={user.photoUrl} />)
          }
        </Circle>
      </PopoverMenu>
    );
  }

  return null;
};

const Circle = styled.div({
  cursor: 'pointer',
  backgroundColor: PRIMARY_COLOR,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
  color: COLORS.WHITE,
  borderRadius: 1000,
  position: 'relative',
});

const Photo = styled.img({
  height: '100%',
  width: '100%',
  position: 'absolute',
  borderRadius: 1000,
});
