import React from 'react';

import Modal from './Modal';
import { useBus } from '../../state';

export default function GlobalModal() {
  const { modalOptions, modal } = useBus();

  if (modalOptions.length === 0) {
    return null;
  }

  const {
    children,
    heading,
    noClose,
    maxWidth,
    onClose,
    renderButtonSlot,
  } = modalOptions[modalOptions.length - 1];

  const handleClose = () => {
    modal.pop();

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      maxWidth={maxWidth}
      noClose={noClose}
      onClose={handleClose}
      heading={heading}
      renderButtonSlot={renderButtonSlot}
    >
      {children}
    </Modal>
  );
}
