import React, {
  FunctionComponent,
  ChangeEventHandler,
  ReactNode,
  useRef } from 'react';
import { Optional } from 'utility-types';
import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

import Icon from '../Icon';
import Text from '../Text';


export type RadioProps = Optional<HTMLInputElement> & {
  label?: ReactNode;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const renderLabel = (label?: ReactNode) => (label ? (
  <StyledLabel as="label">
    {label}
  </StyledLabel>
) : null);

export const Radio: FunctionComponent<RadioProps> = ({
  id,
  name,
  checked,
  label,
  value,
  onChange,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Container onClick={handleClick}>
      <input
        // @ts-ignore
        ref={inputRef}
        style={{
          display: 'none',
        }}
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <Icon
        color={disabled ? COLORS.GRAY_LIGHT : COLORS.INDIGO}
        name={checked ? 'radio-button-on' : 'radio-button-off'}
      />

      {renderLabel(label)}
    </Container>
  );
};

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: 16,
  cursor: 'pointer',
});

const StyledLabel = styled(Text)({
  marginLeft: 32,
  flex: 1,
  cursor: 'inherit',
});
