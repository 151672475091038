import { useState } from 'react';
import createUseContext from 'constate';

import { Project } from '@chronos/constants';

export default createUseContext(() => {
  const [projects, setProjects] = useState<Project[]>([]);

  return {
    projects,
    setProjects,
  };
}, (value) => [value.projects]);
