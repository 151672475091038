import React, { useState } from 'react';
import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

import Text from './Text';

export interface Tab {
  label: string;
}

export interface TabsProps {
  tabs: Tab[];
  initialIndex?: number;
  onTabChange: (tabIndex: number) => void;
}

export default function Tabs({
  tabs,
  initialIndex = 0,
  onTabChange,
}: TabsProps) {
  const [tabIndex, setTabIndex] = useState(initialIndex);

  const renderTab = ({ label }: Tab, index) => (
    <Tab
      key={index}
      selected={tabIndex === index}
      onClick={() => {
        onTabChange(index);
        setTabIndex(index);
      }}
    >
      <Text noSelect semibold={tabIndex === index} light>{label}</Text>
    </Tab>
  );

  return (
    <Container>
      {tabs.map(renderTab)}
    </Container>
  );
}


const Container = styled.div({
  display: 'flex',
  width: '100%',
});

interface TabProps {
  selected: boolean;
}

const Tab = styled.div<TabProps>({
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: 20,
  cursor: 'pointer',
},
({ selected }) => ({
  borderBottomColor: selected ? COLORS.WHITE : COLORS.TRANSPARENT,
}));
