import { useState } from 'react';
import createUseContext from 'constate';

export default createUseContext(() => {
  // This is the milliseconds offset from local epoch time to server epoch time. These times should
  // both be the same value but can differ.
  const [serverEpochTimeOffset, setServerEpochTimeOffset] = useState(0);

  return {
    serverEpochTimeOffset,
    setServerEpochTimeOffset,
  };
}, (value) => [value.serverEpochTimeOffset]);
