import React, { ReactNode, MouseEvent } from 'react';
import usePortal from 'react-useportal';
import styled from '@emotion/styled';
import { css as EmotionCss } from '@emotion/core';

import { COLORS, Shadows } from '@chronos/constants';

import Icon from '../Icon';
import Text from '../Text';
import { ConfirmOptions } from './Confirm';

import media from '../../shared/media';

const contentPadding = 40;
const overlayPadding = 20;

export interface ModalOptions {
  children: ReactNode;
  heading?: string;
  noClose?: boolean;
  hideOverlay?: boolean;
  confirmPrompt?: ConfirmOptions['prompt'];
  maxWidth?: number;
  // TODO implement size/width option
  // size?: number;
  onClose?: () => void;
  renderButtonSlot?: () => JSX.Element;
}

export type ModalProps = ModalOptions & {
  isOpen?: boolean;
};

export default function Modal({
  children,
  noClose,
  hideOverlay,
  heading,
  maxWidth,
  onClose,
  renderButtonSlot,
}: ModalProps) {
  const { Portal } = usePortal();

  // useEffect(() => {
  //   if (children) {
  //     document.getElementById('root')?.classList.add('modal-overlay');

  //     return () => {
  //       document.getElementById('root')?.classList.remove('modal-overlay');
  //     };
  //   }
  // }, []); // eslint-disable-line

  const handleClose = (e: MouseEvent) => {
    const { target } = e;

    // Stop propogation so it only closes this modal instead of all modals.
    e.stopPropagation();

    if (!noClose && onClose && (target as Element).id === 'modal') {
      onClose();
    }
  };

  return (
    <>
      <Portal>
        <Overlay hide={hideOverlay} id="modal" onClick={handleClose}>
          <Content maxWidth={maxWidth}>
            {heading && (
            <HeadingContainer>
              <Text as="h2" semibold>{heading}</Text>
            </HeadingContainer>
            )}
            {noClose ? null : <CloseButton onClose={onClose} />}
            <ScrollContainer>
              {children}
            </ScrollContainer>
            {renderButtonSlot ? (
              <ButtonSlot>
                {renderButtonSlot()}
              </ButtonSlot>
            ) : null}
          </Content>
        </Overlay>
      </Portal>
    </>
  );
}

interface CloseButtonProps {
  onClose?: () => void;
}

class CloseButton extends React.PureComponent<CloseButtonProps> {
  render() {
    const { onClose } = this.props;

    return (
      <FloatingIcon
        size={24}
        hitSlop={contentPadding / 2}
        name="close"
        onClick={onClose}
        clickable
      />
    );
  }
}

interface OverlayProps {
  hide?: boolean;
}

const Overlay = styled.aside<OverlayProps>({
  padding: overlayPadding,
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 800,
  height: '100vh',
  width: '100vw',
  top: 0,
  left: 0,
}, ({ hide }) => !hide && {
  backgroundColor: COLORS.WHITE_OPAQUE,
  height: '100%',
  width: '100%',
});

interface ContentProps {
  maxWidth?: number;
}

const Content = styled.div<ContentProps>({
  maxHeight: '100%',
  padding: contentPadding,
  borderRadius: 8,
  position: 'relative',
  backgroundColor: COLORS.WHITE,
  boxShadow: Shadows.Popover,
  display: 'flex',
  flexDirection: 'column',
  [media('small')]: {
    padding: contentPadding / 2,
  },
}, ({ maxWidth }) => ({
  maxWidth: maxWidth !== undefined ? maxWidth : '100%',
}));

const FloatingIcon = styled(Icon)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 900,
});

const HeadingContainer = styled.div({
  marginBottom: 20,
});

const ScrollContainer = styled.div({
  overflowY: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
});

const ButtonSlot = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: contentPadding,
});
