import { useUser } from '../state';

export default (): {
  getCategories: (type: string) => string[];
} => {
  const { user } = useUser();

  const getCategories = (type: string) => {
    if (user === null) {
      return [];
    }

    return user[`${type.toLowerCase()}Categories`] || [];
  };

  return {
    getCategories,
  };
};
