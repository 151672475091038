import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from '@emotion/styled';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import axios from 'axios';

import { auth, firebase } from '@chronos/firebase';
import { SUBSCRIPTION_STATUS_TYPES, CLOUD_FUNCTIONS_DOMAIN_NAME } from '@chronos/constants';

import { SideNavLink } from './components/SideNav';
import { GuardedRoute, Loading, Button, TopNav, SideNav, GlobalModal } from './components';
import { GlobalStyles } from './GlobalStyles';
import { useAllStates, useAll, useBus, useUser, useServerEpochTimeOffset } from './state';
// const LazyReports = React.lazy(() => import('./pages/Reports'));
// const LazySettings = React.lazy(() => import('./pages/Settings'));
import Confirm from './components/Modal/Confirm';
import { upgradeModalOptions } from './pages/Subscriptions/Upgrade';
import { useGettingStarted } from './hooks';
import Banner from './components/Banner';
import { mobileWarningModalOptions } from './MobileWarning';
import { affiliateLinkPaths } from './pages/Login/AffiliateLoginCompanion';
import MetaTags from 'react-meta-tags';
import media from './shared/media';
import MobileAppInfoBanner from './components/MobileAppInfoBanner';
import PageBottomBannerAd from './components/PageBottomBannerAd';

// JRG: HACK - To help with toggling the "chronos:mvp" localstorage flag.
//*
if (window.location.search.indexOf('mvpOn') !== -1) {
  localStorage.setItem('chronos:mvp', 'true');
} else if (window.location.search.indexOf('mvpOff') !== -1) {
  localStorage.setItem('chronos:mvp', 'false');
}
// */

// JRG: HACK - To help with toggling the "chronos:mvp" localstorage flag.
//*
if (window.location.search.indexOf('invoicesTestOn') !== -1) {
  localStorage.setItem('chronos:invoices', 'true');
} else if (window.location.search.indexOf('invoicesTestOff') !== -1) {
  localStorage.setItem('chronos:invoices', 'false');
}
// */

const links: SideNavLink[] = [
  {
    to: '/projects',
    label: 'Projects',
    iconName: 'rocket',
  },
];

if (localStorage.getItem('chronos:invoices') === 'true') {
  links.push({
    to: '/invoices',
    label: 'Invoices',
    iconName: 'cash',
  });
}

links.push({
  to: '/reports',
  label: 'Reports',
  iconName: 'stats',
}, {
  to: '/settings',
  label: 'Settings',
  iconName: 'settings',
});

const LazyLogin = React.lazy(() => import('./pages/Login/Login'));
const Lazy404 = React.lazy(() => import('./pages/Four04'));
const LazyProjects = React.lazy(() => import('./pages/Projects'));
const LazyInvoices = React.lazy(() => import('./pages/Invoices'));
const LazyReports = React.lazy(() => import('./pages/Reports'));
const LazyReportsSmartEstimates = React.lazy(() => import('./pages/ReportsSmartEstimates'));
const LazyReportsProfitAndLoss = React.lazy(() => import('./pages/ReportsProfitAndLoss'));
const LazyReportsAccountsReceivable = React.lazy(() => import('./pages/ReportsAccountsReceivable'));
const LazyReportsArchivedProjects = React.lazy(() => import('./pages/ReportsArchivedProjects'));
const LazySettings = React.lazy(() => import('./pages/Settings'));
const LazyOnboarding = React.lazy(() => import('./pages/Onboarding'));

const App = () => {
  const { serverEpochTimeOffset, setServerEpochTimeOffset } = useServerEpochTimeOffset();
  const { user } = useUser();
  const { modal, setConfig, config } = useBus();
  const state = useAllStates();
  const actions = useAll();
  const [initialized, setInitialized] = useState(false);

  function collectServerEpochTimeOffset() {
    axios.post<{ timestamp: number }>(`${CLOUD_FUNCTIONS_DOMAIN_NAME}/getServerTime`)
      .then((requestResponse) => {

      if (requestResponse
        && requestResponse.data
        && Number.isFinite(requestResponse.data.timestamp)) {
        const serverTimestamp: number = requestResponse.data.timestamp;
        setServerEpochTimeOffset(serverTimestamp - Date.now());
      }
    });
  }

  useEffect(() => {
    const init = () => {
      if (!initialized) {
        collectServerEpochTimeOffset();

        setInitialized(true);
      }
    };

    return auth.initializeAuth(actions, init);
    // return auth.initializeAuth(actions, init, {
    //   freeTrialDays: config.FREE_TRIAL_DAYS,
    // });
  }, []); // eslint-disable-line

  useEffect(() => {
    const getRemoteConfigValues = async () => {
      try {
        await firebase.remoteConfig.fetchAndActivate();

        const allConfigValues = await firebase.remoteConfig.getAll();
        const values: any = {};

        // TODO: Add types definitions for each
        Object.keys(allConfigValues).forEach((key) => {
          const value = allConfigValues[key];

          try {
            values[key] = JSON.parse(value.asString());
          } catch (parseError) {
            console.error(parseError);
          }
        });

        const mvpOverride = localStorage.getItem('chronos:mvp');

        if (mvpOverride) {
          values.WEB_MVP = mvpOverride === 'true';
        } else {
          values.WEB_MVP = values.WEB_MVP ?? true;
        }

        setConfig(values);
      } catch (e) {
        console.error(e);
      }
    };
    getRemoteConfigValues();
  }, []); // eslint-disable-line

  // JRG: ORIGINAL - Displayed warning message when viewing the web app in a mobile browser.
  /*
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      modal.push(mobileWarningModalOptions);
    }
  }, []); // eslint-disable-line
  // */

  // JRG: ORIGINAL - Disable for now until this is implemented: We show the modal with a dismiss
  // button. If they dismiss the modal then it hides the modal and remembers the date they
  // dismissed it. The modal shows again XXX days after it was last dismissed.
  /*
  useEffect(() => {
    const pcDateCorrect = new Date(Date.now() + serverEpochTimeOffset);

    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      && !config.WEB_MVP && ((user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.TRIAL
      && user?.subscription?.trial?.endDate.toDate
      && user?.subscription?.trial?.endDate.toDate() < pcDateCorrect)
      || user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.EXPIRED
      || user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.CANCELLED)
    ) {
      modal.push(upgradeModalOptions);
    }
  }, [user]); // eslint-disable-line
  */

  useGettingStarted();

  if (!initialized) {
    return <Loading />;
  }

  const logContext = () => {
    console.log(state);
  };

  const showTopNav = Boolean(user);
  const showOnboardingPage = Boolean(user) && user?.preferences.onboardingComplete !== true;
  const showSideNav = !showOnboardingPage && Boolean(user) && !config.WEB_MVP;
  const showBottomBannerAd = (user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.TRIAL);

  const isAffiliatePath = affiliateLinkPaths.includes(window.location.pathname);
  const isDevSite = window.location.hostname.indexOf('dev.') === 0;

  return (
    <>
    {(isAffiliatePath || isDevSite) && (
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
    )}
      <Flex>
        {
          localStorage.getItem('chronos:logContext') === 'true' && (
            <FloatingButton
              lg
              width="auto"
              onClick={logContext}
            >
              Log Context
            </FloatingButton>
          )
        }

        <GlobalStyles />

        { showTopNav && <TopNav showSideNav={showSideNav} /> }
        { showSideNav && <SideNav links={links} /> }

        <Main showTopNav={showTopNav} showSideNav={showSideNav} showBottomBannerAd={showBottomBannerAd}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              {!config.WEB_MVP && <Redirect exact from="/" to="/projects" />}
              {/* <Route path="/forgot-password" render={() => <div>Forgot Password</div>} /> */}
              <Route path={['/login', '/signup', '/forgot-password', ...affiliateLinkPaths]} component={LazyLogin} />

              {showOnboardingPage ? (<Route component={LazyOnboarding} />) :
              (<Switch>
                <GuardedRoute path="/projects" component={LazyProjects} />
                <GuardedRoute path="/invoices" component={LazyInvoices} />
                <GuardedRoute path="/reports/smart-estimates" component={LazyReportsSmartEstimates} />
                <GuardedRoute path="/reports/profit-loss" component={LazyReportsProfitAndLoss} />
                <GuardedRoute path="/reports/accounts-receivable" component={LazyReportsAccountsReceivable} />
                <GuardedRoute path="/reports/archived-projects" component={LazyReportsArchivedProjects} />
                <GuardedRoute path="/reports" component={LazyReports} />
                <GuardedRoute path="/settings" component={LazySettings} />
                <GuardedRoute component={Lazy404} />
              </Switch>)
              }

              <Route component={Lazy404} />
            </Switch>
          </React.Suspense>
          { showBottomBannerAd && <PageBottomBannerAd showSideNav={showSideNav} />}
        </Main>
      </Flex>
      <Banner showSideNav={showSideNav} showTopNav={showTopNav} />
      <MobileAppInfoBanner />
      <Confirm />
      <GlobalModal />
    </>
  );
};

const Flex = styled.div({
  display: 'flex',
});

interface MainProps {
  showTopNav: boolean;
  showSideNav: boolean;
  showBottomBannerAd: boolean;
}

const Main = styled.div<MainProps>({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
},
({ showTopNav, showSideNav, showBottomBannerAd }) => ({
  flex: 1,
  ...(showTopNav && { paddingTop: 60 }),
  ...(showSideNav && { paddingLeft: 80 }),
  ...(showBottomBannerAd && { paddingBottom: '20vh' }),

  [media('small')]: {
    ...(showSideNav && {
      marginLeft: 0,
      marginBottom: 80,
    }),
  },
}));

const FloatingButton = styled(Button)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: 10,
});

export default App;
