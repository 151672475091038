import React, { ReactElement } from 'react';
import { RouteProps as ReactRouteProps, Route, Redirect, useLocation } from 'react-router';

import { useFirebaseUser } from '../state';

export type RouteProps = ReactRouteProps & {
};

export default ({ component: Component, render, ...rest }: RouteProps): ReactElement | null => {
  const { firebaseUser } = useFirebaseUser();
  const location = useLocation();

  // if (location.state && location.state.fromLogin) {
  //   // TODO how to make login to gaurded route, not flash/re-render
  //   // history.goBack();
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (firebaseUser !== null) {
          if (Component) {
            return <Component {...props} />;
          }

          if (render) {
            return render(props);
          }
        }

        return (
          <Redirect
            to={{
              pathname: '/signup',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
