const bp = {
  small: '@media(max-width: 768px)',
  medium: '@media(min-width: 769px) and (max-width: 1200px)',
  large: '@media(min-width: 1200px)',
};

export default function media(n) {
  const bpArray = Object.keys(bp).map((key) => [key, bp[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, size];
    return acc;
  }, []);

  return result;
}
