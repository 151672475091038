import { useState } from 'react';
import createUseContext from 'constate';

import { ProjectType } from '@chronos/constants';

export default createUseContext(() => {
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);

  return {
    projectTypes,
    setProjectTypes,
  };
}, (value) => [value.projectTypes]);
