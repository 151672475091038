import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { LocationDescriptor, Location } from 'history';
import styled from '@emotion/styled';

import { COLORS, PRIMARY_COLOR } from '@chronos/constants';
import Icon from '../components/Icon';
import media from '../shared/media';

export type SideNavLink = NavLinkProps & { label: string; iconName: string };

export interface SideNavProps {
  links: SideNavLink[];
}

const SideNav = ({ links = [] }: SideNavProps) => (
  <Container>
    <IconContainer>
      {/* <Icon hitSlop={16} color={COLORS.GRAY_LIGHT} name="menu" /> */}
    </IconContainer>
    <LinksContainer>
      {
          links.map(({ label, to, iconName, ...rest }) => {
            const toWithFrom = (location: Location): LocationDescriptor => {
              const fromLogin = ['/login', '/signup'].includes(location.pathname);

              if (typeof to === 'string') {
                return {
                  pathname: to,
                  state: {
                    fromLogin,
                  },
                };
              }

              return {
                ...to,
                state: {
                  ...(to as any).state || {},
                  fromLogin,
                },
              };
            };

            return (
              <StyledNavLink
                isActive={(match, location) => {
                  if (!match) {
                    return false;
                  }

                  if (to === '/reports') {
                    return (location.pathname === '/reports'
                    || location.pathname.includes('/reports/'));
                  }

                  return to === location.pathname;
                }}
                key={label.toLowerCase()}
                // @ts-ignore
                to={toWithFrom}
                {...rest}
              >
                <StyledNavLinkIcon name={iconName} />
                <StyledNavLinkText className="xexpandable">
                  {label}
                </StyledNavLinkText>
              </StyledNavLink>
            );
          })
        }
    </LinksContainer>
  </Container>
);

export default SideNav;

const Container = styled.div({
  width: 80,
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRight: `1px solid ${COLORS.GRAY_LIGHT}`,
  backgroundColor: COLORS.WHITE,
  height: '100vh',
  zIndex: 100,
  ':hover': {
    '.expandable': {
      display: 'inline',
    },
  },

  [media('small')]: {
    bottom: 0,
    height: 80,
    width: '100%',
    flexDirection: 'row',
    borderRight: 'none',
  },
});

const LinksContainer = styled.div({
  width: '100%',
  marginTop: 100,
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',

  [media('small')]: {
    height: '100%',
    flexDirection: 'row',
    marginTop: 0,
  },
});

const IconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: 80,
});

const StyledNavLink = styled(NavLink)({
  flex: 1,
  width: '100%',
  textDecoration: 'none',
  padding: '24px 32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: `4px solid ${COLORS.TRANSPARENT}`,

  [media('small')]: {
    height: '100%',
    borderLeft: 'none',
    borderBottom: `4px solid ${COLORS.TRANSPARENT}`,
  },

  color: PRIMARY_COLOR,
  ':hover': {
    borderLeftColor: COLORS.INDIGO_LIGHT,

    [media('small')]: {
      borderLeftColor: 'initial',
      borderBottomColor: COLORS.INDIGO_LIGHT,
    },

    i: {
      color: COLORS.INDIGO_LIGHT,
    },
    '.expandable': {
      color: COLORS.INDIGO_LIGHT,
    },
  },
  '&.active': {
    borderLeftColor: PRIMARY_COLOR,

    [media('small')]: {
      borderLeftColor: 'initial',
      borderBottomColor: PRIMARY_COLOR,
    },

    i: {
      color: PRIMARY_COLOR,
    },
    '.expandable': {
      color: PRIMARY_COLOR,
    },
  },
});

const StyledNavLinkIcon = styled(Icon)({
  color: COLORS.GRAY_LIGHT,
});

const StyledNavLinkText = styled.span({
  display: 'none',
  fontWeight: 500,
  color: COLORS.GRAY_LIGHT,
  marginLeft: 20,
});
