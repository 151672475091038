import React from 'react';
import ReactToggle from 'react-toggle';
import styled from '@emotion/styled';
import 'react-toggle/style.css';

import { PRIMARY_COLOR, COLORS } from '@chronos/constants';
import isPropValid from '@emotion/is-prop-valid';

// JRG: TODO - prop 'light' appears unused. Remove it?
export type ToggleProps = Omit<ReactToggle['props'], 'light'> & {
  light?: boolean;
}

export default function Toggle(props: ToggleProps) {
  return (
    <StyledToggle
      {...props}
    />
  );
}

interface StyledToggleProps {
  light?: boolean;
}

const StyledToggle = styled(ReactToggle, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'light',
})<StyledToggleProps>({
  '& .react-toggle-thumb': {
    boxShadow: 'none !important',
    border: '0 !important',
  },
  '& .react-toggle-track-check': {
    display: 'none',
  },
  '& .react-toggle-track-x': {
    display: 'none',
  },
},
({ light }) => ({
  ...({
    '& .react-toggle-track': {
      backgroundColor: `${COLORS.BLACK} !important`,
    },
    '&.react-toggle--checked .react-toggle-track': {
      backgroundColor: `${COLORS.GRAY_LIGHT} !important`,
    },
  }),
}));
