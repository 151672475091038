import { useState } from 'react';
import createUseContext from 'constate';

import { BannerOptions } from '../components/Banner';
import { ConfirmOptions } from '../components/Modal/Confirm';
import { ModalOptions } from '../components/Modal/Modal';

export default createUseContext(() => {
  let confirmModalClose = false;

  const [modalOptions, setModal] = useState<ModalOptions[]>([]);
  const [config, setConfig] = useState<any>({});
  const [bannerOptions, setBanner] = useState<BannerOptions | null>(null);
  const [confirmOptions, setConfirm] = useState<ConfirmOptions | null>(null);

  const promiseConfirm = (confirm: Omit<ConfirmOptions, 'onClose'> | null): Promise<boolean> => new Promise((resolve) => {
    setConfirm({
      ...confirm,
      onClose: (confirmed) => resolve(confirmed),
    } as ConfirmOptions);
  });

  const modal = {
    push: (m: ModalOptions) => setModal([...modalOptions, m]),
    pop: async () => {
      if (confirmModalClose) {
        const last = modalOptions[modalOptions.length - 1];
        const prompt = last.confirmPrompt ?? 'Are you sure you want to leave close?';

        const shouldPop = await promiseConfirm({
          prompt,
          hideOverlay: true,
        });

        if (!shouldPop) return;
      }

      setModal(modalOptions.slice(0, -1));
    },
    replace: (m: ModalOptions) => setModal([m]),
    setConfirm: (c: boolean) => {
      confirmModalClose = c;
    },
  };

  return {
    config,
    setConfig,
    modalOptions,
    modal,
    bannerOptions,
    banner: setBanner,
    confirmOptions,
    setConfirm,
    confirm: promiseConfirm,
  };
});
