import React from 'react';
import styled from '@emotion/styled';
import media from '../../shared/media';
import { Text } from '../../components';

export default function AffiliateSignupCompanionFreelancingFreedom() {
  return (
    <SlidesContainer>
      <Slide>
        <HeaderImage alt="" src="images/affiliate-freelancingfreedom-icon.png" />
        <TitleText>Special Offer for Brad Hussey's Listeners</TitleText>
        <SubtitleText>Sign up for your free Chronos account and get a month of Pro access on the house! Track your time and charge what you're worth.</SubtitleText>
        <PhoneImagesContainer>
          <PhoneImage alt="" src="images/phone-project-stats.png" />
          <PhoneImage alt="" src="images/phone-smart-estimates.png" />
        </PhoneImagesContainer>
      </Slide>
    </SlidesContainer>
  );
}

const TEXT_COLOR_WHITE = '#FAFAFA';

const SlidesContainer = styled.div({
  position: 'relative',
  width: '53%',
  height: '100%',
  overflow: 'hidden',
  userSelect: 'none',
  '.icon-arrow-left': {
    transition: 'opacity 0.2s',
    opacity: 0,
  },
  '&:hover': {
    '.icon-arrow-left': {
      opacity: 1,
    }
  },
  [media('small')]: {
    display: 'none',
  }
});

const Slide = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: '#FFCA00',
}));

const TitleText = styled(Text)({
  color: TEXT_COLOR_WHITE,
  display: 'inline-block',
  width: '80%',
  // Use viewport width font size so the occupies two full lines any window size.
  fontSize: '3vw',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: 16,
});

const SubtitleText = styled(Text)({
  color: TEXT_COLOR_WHITE,
  display: 'inline-block',
  width: '70%',
  // Use viewport width font size so the occupies two full lines any window size.
  fontSize: '1.8vw',
  // fontWeight: 'bold',
  textAlign: 'center',
  marginTop: 10,
});

const StyledImage = styled.img({
  userSelect: 'none',
});

const HeaderImage = styled(StyledImage)({
  height: 'auto',
  width: '42%',
  marginTop: '15px',
  clipPath: 'circle(34% at center)',
});

const PhoneImagesContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const PhoneImage = styled(StyledImage)({
  height: 'auto',
  width: '40%',
  marginTop: '15px',
});

const TitleTrackTime = styled(Text)({
  color: TEXT_COLOR_WHITE,
  display: 'inline-block',
  width: '80%',
  // Use viewport width font size so the occupies two full lines any window size.
  fontSize: '3.5vw',
  fontWeight: 'bold',
  textAlign: 'center',
});
