import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

import Text from './Text';

export const InputLabel = styled(Text)<{ htmlFor: string; mark?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  user-select: none;

  ${({ mark = false }) => (mark ? `
    &:after {
      content: '*';
      color: ${COLORS.RED};
    }
  ` : '')}
`;
