import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';

import { COLORS } from '@chronos/constants';

export type IconProps = StyledIconProps & {
  name: string;
  className?: string;
  ios?: boolean;
}

export default ({
  name,
  onClick,
  className = '',
  ios = false,
  disabled,
  clickable,
  ...styleProps
}: IconProps) => {
  const iconName = `ion-${ios ? 'ios' : 'md'}-${name.replace(/(md|ios)-/g, '')}`;
  const classes = [className, `icon ${iconName}`].join(' ');
  if (!onClick) {
    return (
      <StyledIcon
        {...styleProps}
        clickable={clickable}
        className={classes}
      />
    );
  }

  const handleClick: MouseEventHandler = (event) => {
    if (!disabled) {
      onClick(event);
    }
  };

  return (
    <StyledIcon
      {...styleProps}
      disabled={disabled}
      onClick={handleClick}
      className={classes}
    />
  );
};

interface StyledIconProps {
  size?: number;
  width?: number | string;
  height?: number | string;
  color?: COLORS | string;
  fill?: COLORS | string;
  stroke?: COLORS | string;
  hitSlop?: number;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  rotate?: number;
  clickable?: boolean;
}

const StyledIcon = styled.i<StyledIconProps>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
({
  onClick,
  clickable,
  size = 24,
  width = null,
  height = null,
  disabled = false,
  color = COLORS.BLACK,
  hitSlop = 0,
  rotate = 0,
  fill,
  stroke,
}) => ({
  fontSize: size,
  width: width ? width : size * 1.5,
  height: height ? height : size * 1.5,
  color,
  padding: hitSlop,
  transform: `rotate(${rotate}deg)`,
  cursor: (onClick || clickable) && !disabled ? 'pointer' : (onClick || clickable) ? 'not-allowed' : 'inherit',
  borderRadius: 1000,
  ...(fill && { backgroundColor: fill }),
  ...(stroke && { border: `1px solid ${stroke}` }),
}));
