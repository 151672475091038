import React from 'react';
import styled from '@emotion/styled';

export interface SpacerProps {
  size?: number;
}

export default function Spacer({ size = 10 }: SpacerProps) {
  return (
    <Container size={size} />
  );
}

interface ContainerProps {
  size: number;
}

const Container = styled.div<ContainerProps>(
  ({ size }) => ({
    height: size,
    width: size,
  }),
);
