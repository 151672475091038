import React from 'react';
import { NavLinkProps } from 'react-router-dom';
import styled from '@emotion/styled';

import { COLORS, PRIMARY_COLOR } from '@chronos/constants';

import UserProfile from './UserProfile';
import Logo from './Logo';
import Button from './Button';
import Spacer from './Spacer';
import { getCheckoutModalOptions } from '../pages/Subscriptions/Checkout';
import { useUser, useBus } from '../state';
import { SUBSCRIPTION_STATUS_TYPES } from '@chronos/constants';
import media from '../shared/media';

export type TopNavLink = NavLinkProps & { label: string };

export interface TopNavProps {
  showSideNav: boolean;
}

const TopNav = ({ showSideNav }: TopNavProps) => {
  const { user } = useUser();
  const { modal, config } = useBus();

  const openUpgradeModal = () => {
    modal.push(getCheckoutModalOptions({ onSuccess: modal.pop }));
  };

  return (
    <>
      <Container showSideNav={showSideNav}>
        <Relative>
          <LeftContainer>
            <Logo size={46} />
            <Logo text />
          </LeftContainer>

          <RightContainer>
            {
              (!(user?.subscription?.status === SUBSCRIPTION_STATUS_TYPES.SUBSCRIBED) && !config.WEB_MVP) && (
                <UpgradeButtonStyled primary onClick={openUpgradeModal}>Upgrade</UpgradeButtonStyled>
              )
            }
            <Spacer size={0} />
            <Help target="_blank" href="https://help.chronostimetracking.com/">?</Help>
            <UserProfile user={user} />
          </RightContainer>
        </Relative>
      </Container>
    </>
  );
};

export default TopNav;

interface ContainerProps {
  showSideNav: boolean;
}

const Container = styled.div<ContainerProps>({
  position: 'fixed',
  borderBottom: `1px solid ${COLORS.GRAY_LIGHTER}`,
  backgroundColor: COLORS.WHITE,
  zIndex: 99,
},
({ showSideNav }) => ({
  width: '100%',
  ...(showSideNav && {
    marginLeft: 80,
    width: 'calc(100% - 60px)',

    [media('small')]: {
      marginLeft: 0,
      width: '100%',
    },
  }),
}));

const Relative = styled.div({
  height: 60,
  padding: '16px 18px',
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const LeftContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '& >:not(:last-child)': {
    marginRight: 10,
  },
});

const RightContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginRight: 20,
  },
});

const Help = styled.a({
  flexShrink: 0,
  height: 32,
  width: 32,
  fontSize: 18,
  textDecoration: 'none',
  border: `2px solid ${PRIMARY_COLOR}`,
  borderRadius: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: PRIMARY_COLOR,
  fontWeight: 500,
});

const UpgradeButtonStyled = styled(Button)({
  [media('small')]: {
    padding: '6px 10px',
    fontSize: '12px',
    borderRadius: '5px',
  },
});
