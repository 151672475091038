import React, { ReactNode, CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Interpolation } from '@emotion/serialize';
import isPropValid from '@emotion/is-prop-valid';

import { COLORS } from '@chronos/constants';

export type TextProps = StyledTextProps & {
  children: ReactNode;
  style?: CSSProperties;
  title?: string;
}

export default function Text({
  as = 'span',
  children,
  className,
  style,
  title,
  ...textProps
}: TextProps) {
  return (
    <StyledText style={style} as={as} className={className} title={title} {...textProps}>
      {children}
    </StyledText>
  );
}


interface StyledTextProps {
  as?: 'h1' | 'h2' | 'h3' | 'span' | 'p' | 'label' | 'small';
  color?: COLORS;
  light?: boolean;
  center?: boolean;
  hCenter?: boolean;
  vCenter?: boolean;
  bold?: boolean;
  semibold?: boolean;
  thin?: boolean;
  weight?: number;
  maxWidth?: number;
  size?: number;
  italic?: boolean;
  monospace?: boolean;
  label?: boolean;
  uppercase?: boolean;
  noSelect?: boolean;
  className?: string;
}

const StyledText = styled('h1', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'label',
})<StyledTextProps>({
  fontSize: 14,
  color: COLORS.BLACK,
  fontFamily: '\'Montserrat\', \'Helvetica Neue\', sans-serif',
  fontWeight: 300,
}, ({
  as,
  color,
  light,
  center,
  hCenter,
  vCenter,
  bold,
  semibold,
  thin,
  weight,
  size,
  italic,
  monospace,
  label,
  uppercase,
  noSelect,
  maxWidth,
}) => {
  const styles: Interpolation<StyledTextProps & { theme: object }> = {};
  if (color) {
    styles.color = color;
  } else if (light) {
    styles.color = COLORS.WHITE;
  }

  if (maxWidth) {
    styles.maxWidth = maxWidth;
  }

  if (size) {
    styles.fontSize = size;
  } else if (as === 'h1') {
    styles.fontSize = 28;
  } else if (as === 'h2') {
    styles.fontSize = 20;
  } else if (as === 'h3') {
    styles.fontSize = 18;
  } else if (as === 'small') {
    styles.fontSize = 12;
  }


  if (weight) {
    styles.fontWeight = weight;
  } else if (bold) {
    styles.fontWeight = 700;
  } else if (semibold) {
    styles.fontWeight = 500;
  } else if (thin) {
    styles.fontWeight = 200;
  }

  if (label) {
    styles.letterSpacing = 1.13;
    styles.textTransform = 'uppercase';
  } else if (monospace) {
    styles.fontFamily = 'Courier New';
  }

  if (italic) {
    styles.fontStyle = 'italic';
  }


  if (center) {
    styles.textAlign = 'center';
  }

  if (hCenter || vCenter) {
    styles.display = 'flex';

    if (hCenter) {
      styles.alignItems = 'center';
    }

    if (vCenter) {
      styles.justifyContent = 'center';
    }
  }

  if (uppercase) {
    styles.textTransform = 'uppercase';
  }

  if (noSelect) {
    styles.userSelect = 'none';
  }

  return styles;
});
