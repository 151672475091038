import React from 'react';
import styled from '@emotion/styled';
import { COLORS, BorderRadius } from '@chronos/constants';

import Text from './Text';
import Button from './Button';
import Spacer from './Spacer';

interface ButtonGroupOption {
  label: string;
  value: string | number;
}

export interface ButtonGroupProps {
  label?: string;
  options: ButtonGroupOption[];
  value?: string | number | null;
  // multi?: boolean;
  onChange: (value: string | number | null) => void;
}

export default function ButtonGroup({
  value = null,
  label = '',
  options,
  onChange,
}: ButtonGroupProps) {
  const isButtonActive = (buttonValue) => (value) === buttonValue;
  const handleClick = (index: number) => {
    const buttonPressed = options[index];

    if (isButtonActive(buttonPressed.value)) {
      onChange(null);
    } else {
      onChange(buttonPressed.value);
    }
  };

  const renderLabel = () => (
    <>
      <Text label center>{label}</Text>
      <Spacer />
    </>
  );

  return (
    <Container>
      { label && renderLabel() }

      <ButtonContainer>
        {
          options.map(({ label: buttonLabel, value: buttonValue }, index) => (
            <StyledButton
              primary={isButtonActive(buttonValue)}
              light={!isButtonActive(buttonValue)}
              key={buttonValue}
              onClick={() => handleClick(index)}
            >
              {buttonLabel}
            </StyledButton>
          ))
        }
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div({
});

const ButtonContainer = styled.div({
  display: 'flex',
  overflow: 'hidden',
  borderRadius: BorderRadius,
  border: `1px solid ${COLORS.GRAY_LIGHT}`,
});

const StyledButton = styled(Button)({
  flex: 1,
  margin: 0,
  padding: '10px 0',
  borderRadius: 0,
  ':not(:last-of-type)': {
    borderRight: `1px solid ${COLORS.GRAY_LIGHT}`,
  },
});
