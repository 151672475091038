import React from 'react';
import styled from '@emotion/styled';

import {
  ProjectType,
  FILTER_DUE_DATE_BUTTONS,
  FILTER_DATE_CREATED_BUTTONS,
} from '@chronos/constants';

import Toggle from './Toggle';
import Text from './Text';
import Select from './Select';
import Spacer from './Spacer';
import ButtonGroup from './ButtonGroup';
import TextButton from './TextButton';
import { useProjectTypes, useProjectFilters, useBus } from '../state';
import SearchBar from './SearchBar';


export interface ProjectsSearchBarProps {
}

const SearchBarModel = ({ clearQuery }) => {
  const { projectTypes } = useProjectTypes();
  const {
    filterArchived,
    setFilterArchived,
    filterProjectType,
    setFilterProjectType,
    filterDateCreated,
    setFilterDateCreated,
    filterDueDate,
    setFilterDueDate,
  } = useProjectFilters();

  const clearAll = () => {
    clearQuery();
    setFilterArchived(false);
    setFilterProjectType(null);
    setFilterDateCreated(null);
    setFilterDueDate(null);
  };

  return (
    <ModalOptions>
      <DisplayRow>
        <Text label>Show Archived Projects</Text>
        <Toggle
          checked={filterArchived}
          onChange={(e) => setFilterArchived(e.target.checked)}
        />
      </DisplayRow>
      <Select<Pick<ProjectType, 'id' | 'name'>>
        bvo
        label="Project Type"
        value={filterProjectType || 'all'}
        options={[
          {
            id: 'all',
            name: 'All Projects',
          },
          ...projectTypes,
        ]}
      // TODO: fix select types for bvo
        onSelect={(p) => {
          // @ts-ignore
          if (p === 'all') {
            setFilterProjectType(null);
          } else {
            // @ts-ignore
            setFilterProjectType(p as string);
          }
        }}
        valueProp="id"
        labelProp="name"
        width={400}
      />
      <ButtonGroup
        label="Projects created in the last..."
        onChange={(v) => setFilterDateCreated(v as number)}
        value={filterDateCreated}
        options={FILTER_DATE_CREATED_BUTTONS}
      />
      <ButtonGroup
        label="Projects due in the next..."
        onChange={(v) => setFilterDueDate(v as number)}
        value={filterDueDate}
        options={FILTER_DUE_DATE_BUTTONS}
      />
      <Spacer />
      <TextButton text="Clear" onClick={clearAll} />
    </ModalOptions>
  );
};

export default function ProjectsSearchBar(props: ProjectsSearchBarProps) {
  const { modal } = useBus();
  const {
    hasFilters,
    query,
    setQuery,
  } = useProjectFilters();

  const clearQuery = () => {
    setQuery('');
  };

  const launchFilterModal = () => modal.push({
    heading: 'Filter',
    children: <SearchBarModel clearQuery={clearQuery} />,
  });

  return (
    <SearchBar
      query={query}
      setQuery={setQuery}
      hasFilters={hasFilters}
      onClickFilterButton={launchFilterModal}
    />
  );
}

const ModalOptions = styled.div({
  '& >:not(:last-child)': {
    marginBottom: 20,
  },
});

const DisplayRow = styled.div({
  margin: '10px 0',
  display: 'flex',
  justifyContent: 'space-between',
});
