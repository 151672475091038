"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderElement = void 0;

exports.renderElement = function (element) {
  if (typeof element === 'function') {
    return element();
  }

  return element;
};