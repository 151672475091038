import { useState } from 'react';
import createUseContext from 'constate';

export default createUseContext(() => {
  const [query, setQuery] = useState('');
  const [filterArchived, setFilterArchived] = useState(false);
  const [filterProjectType, setFilterProjectType] = useState<string | null>(null);
  const [filterDateCreated, setFilterDateCreated] = useState<number | null>(null);
  const [filterDueDate, setFilterDueDate] = useState<number | null>(null);

  return {
    hasFilters: Boolean(query || filterArchived || filterProjectType || filterDateCreated || filterDueDate),
    query,
    setQuery,
    filterArchived,
    setFilterArchived,
    filterProjectType,
    setFilterProjectType,
    filterDateCreated,
    setFilterDateCreated,
    filterDueDate,
    setFilterDueDate,
  };
});
