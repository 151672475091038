import React, { useState } from 'react';
import { Moment } from 'moment';
import styled from '@emotion/styled';
import { Reference, Popper, Manager, PopperChildrenProps } from 'react-popper';
import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from 'react-dates';
import { createPortal } from 'react-dom';

import { COLORS, Shadows, ChronosDate } from '@chronos/constants';
import { getMoment } from '@chronos/utils';

import './react_dates_overrides.css';

import Input, { InputProps } from '../Input';

export type DatePickerProps = Partial<DayPickerSingleDateControllerShape> & Omit<InputProps, 'value' | 'onChange' | 'onChangeValue'> & {
  dateFormat?: string;
  value?: ChronosDate | Moment | number | string | null;
  onChange: (date: Date | null) => void;
}

export default function DatePicker({
  label,
  value,
  onChange,
  dateFormat = 'YYYY-MM-DD',
  clearable = false,
  ...rest
}: DatePickerProps) {
  let blockBlur = false;
  const [isOpen, setIsOpen] = useState(false);
  const date = value ? getMoment(value) : null;

  const handleBlur = () => {
    if (!blockBlur) {
      setIsOpen(false);
    }
  };

  const onChangeDate = (newDate: Moment | null) => {
    onChange(newDate && newDate.toDate());
    setIsOpen(false);
  };

  const onClearDate = () => {
    onChange(null);
  };

  const renderDropdown = () => (
    <Dropdown onMouseEnter={() => { blockBlur = true; }} onMouseLeave={() => { blockBlur = false; }}>
      <DayPickerSingleDateController
        focused
        date={date}
        numberOfMonths={1}
        onDateChange={onChangeDate}
        onFocusChange={() => undefined}
      />
    </Dropdown>
  );

  const dateLabel = date && date.format(dateFormat);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <TargetContent ref={ref}>
            <Input
              readOnly
              {...rest}
              ref={undefined}
              focused={isOpen}
              label={label}
              value={dateLabel || ''}
              onFocus={() => setIsOpen(true)}
              onBlur={handleBlur}
              // placeholder="What's the name of the project?"
              clearable={clearable}
              onClear={clearable ? onClearDate : undefined}
            />
          </TargetContent>
        )}
      </Reference>
      {
        createPortal(
          <Popper
            modifiers={{
              preventOverflow: {
                padding: 20,
                boundariesElement: 'window',
                priority: ['bottom', 'top', 'left', 'right'],
              },
              computeStyle: {
                fn: (data) => ({
                  ...data,
                  // Figure out why this complains like a little bitch
                  // @ts-ignore
                  styles: {
                    ...data.offsets.popper,
                    width: data.offsets.reference.width,
                  } as CSSStyleDeclaration,
                }),
              },
            }}
          >
            {(popperProps: PopperChildrenProps) => (isOpen ? (
              <PopperContent
                {...popperProps}
                onClick={(e) => {
                  e.nativeEvent.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  setIsOpen(false);
                }}
                data-placement={popperProps.placement}
              >
                {renderDropdown()}
              </PopperContent>
            ) : null)}
          </Popper>,
          // TODO: fix to have fallback if popover doesn't exist
          document.querySelector('#date-picker')!,
        )
      }
    </Manager>
  );
}

const TargetContent = styled.div({
  cursor: 'pointer',
});

const PopperContent = styled.div({
  zIndex: 1000,
});

const Dropdown = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 5,
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: COLORS.WHITE,
  zIndex: 900,
  borderRadius: 8,
  boxShadow: Shadows.Popover,
});
