"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUserSubscribed = void 0;

var constants_1 = require("@chronos/constants");

exports.isUserSubscribed = function (user) {
  var _a;

  return ((_a = user === null || user === void 0 ? void 0 : user.subscription) === null || _a === void 0 ? void 0 : _a.status) === constants_1.SUBSCRIPTION_STATUS_TYPES.SUBSCRIBED;
};